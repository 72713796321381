import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { Line } from "react-chartjs-2";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_rangeSelector from "@amcharts/amcharts4/plugins/rangeSelector";
import LiveChart from "./LiveChart";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import { fetchDashboardStart } from "../store/actions/DashboardAction";
import { fetchTokenListStart } from "../store/actions/TokenAction";

am4core.useTheme(am4themes_animated);

const useStylesBootstrap = makeStyles((theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
		top: "8px",
		color: "#fff",
		fontSize: "1em",
		padding: "0.75em",
	},
}));

function BootstrapTooltip(props) {
	const classes = useStylesBootstrap();
	return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const useTableStyles = makeStyles({
	table: {
		backgroundColor: "transparent",
		color: "#828282",
		"& .MuiTableCell-stickyHeader": {
			backgroundColor: "#171717",
			color: "#828282",
			borderBottom: "1.5px solid #828282",
			minWidth: "150px",
			maxWidth: "200px",
		},
		"& .MuiTableCell-stickyHeader:first-child": {
			minWidth: "50px",
			maxWidth: "50px",
		},
		"& .MuiTableCell-body": {
			color: "#828282",
			borderBottom: "1.5px solid #828282",
			maxWidth: "200px",
			overflow: "hidden",
		},
		"& .MuiTableRow-root:last-child .MuiTableCell-body": {
			border: "none"
		}
	},
	MuiTablePagination: {
		backgroundColor: "red",
	},
});

const DashBoard = (props) => {
	const tableclasses = useTableStyles();

	useEffect(() => {
		props.dispatch(fetchDashboardStart());
		props.dispatch(fetchTokenListStart());
	}, []);

	const firstLineChart = {
		labels: ["2016", "2016", "2016", "2017", "2017", "2018"],
		datasets: [
			{
				label: "Sell",
				fill: false,
				borderColor: "#e0bc00",
				backgroundColor: "e0bc00",
				data: [0, 1.5, 2.5, 2, 3, 5],
				tension: 0.5,
			},
			{
				label: "Buy",
				fill: false,
				borderColor: "#00c292",
				backgroundColor: "#00c292",
				data: [0, 0.75, 1, 1.75, 2.5, 3],
				tension: 0.5,
			},
		],
	};

	const secondLineChart = {
		labels: ["2016", "2016", "2016", "2017", "2017", "2018"],
		datasets: [
			{
				label: "Sell ",
				fill: false,
				borderColor: "#e0bc00",
				backgroundColor: "e0bc00",
				data: [0, 1.5, 2.5, 2, 3, 5],
				tension: 0.5,
			},
			{
				label: "Buy",
				fill: false,
				borderColor: "#00c292",
				backgroundColor: "#00c292",
				data: [0, 0.75, 1, 1.75, 2.5, 3],
				tension: 0.5,
			},
		],
	};

	const thirdLineChart = {
		labels: ["2016", "2016", "2016", "2017", "2017", "2018"],
		datasets: [
			{
				label: "Sell ",
				fill: false,
				borderColor: "#e0bc00",
				backgroundColor: "e0bc00",
				data: [0, 1.5, 2.5, 2, 3, 5],
				tension: 0.5,
			},
			{
				label: "Buy ",
				fill: false,
				borderColor: "#00c292",
				backgroundColor: "#00c292",
				data: [0, 2, 0.75, 1.5, 2, 4],
				tension: 0.5,
			},
		],
	};

	return (
		<>
			<div className="content-wrapper min-heigth-100vh">
				<Helmet>
					<script
						type="text/javascript"
						src={window.location.origin + "/assets/js/pages/dashboard.js"}
					></script>
					<script
						type="text/javascript"
						src={window.location.origin + "/assets/js/pages/dashboard-chart.js"}
					></script>
				</Helmet>
				<section className="content-header">
					<h1>
						Dashboard
					</h1>
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<a href="#">
								<i className="fa fa-dashboard"></i> Home
							</a>
						</li>
						<li className="breadcrumb-item active">Dashboard</li>
					</ol>
				</section>
				<section className="container-fluid p-4">
					{/* <div className="box box-inverse bg-yellow">
								<div className="box-body">
										<h1 className="page-header text-center no-border font-size-40 font-weight-600"><span className="text-dark">Buy and sell Coins at the<br /> Crypto without additional fees</span></h1>
										<h5 className="subtitle text-center text-white"><span className="text-dark">Buy now and get +30% extra bonus Minimum pre-sale<br /> amount 50 Crypto Coin. We accept BTC crypto-currency.</span></h5>
										<div className="row">
												<div className="col-12">
														<div className="exchange-calculator dash-1 text-center mt-35">
																<input type="text" className="form-control mb-5" name="coins-exchange" placeholder="" value="10.1548" />                
																<select className="coins-exchange" name="state">
																		<option value="BTC">BTC</option>
																		<option value="BTC">Ethereum</option>
																		<option value="Ripple">Ripple</option>
																		<option value="Ripple">Bitcoin Cash</option>
																		<option value="Ripple">Cardano</option>
																		<option value="Ripple">Litecoin</option>
																		<option value="Ripple">NEO</option>
																		<option value="Ripple">Stellar</option>
																		<option value="Ripple">EOS</option>
																		<option value="Ripple">NEM</option>
																</select>
																<div className="equal"> = </div>
																<input type="text" className="form-control mb-5" name="money-exchange" placeholder="" value="125.158" />                
																<select className="money-exchange" name="state">
																		<option value="USD">USD</option>
																		<option value="EURO">EURO</option>
																</select>
														</div>
														<div className="text-center mt-15">
																<a href="#" className="btn mx-auto btn-dark">EXCHANGE NOW</a>
														</div>
												</div>
										</div>
								</div>
						</div> */}
					{props.dashboardInfo.loading ? (
						"Loading..."
					) : (
						<>
							<div className="row">
								<div className="col-xl-3 col-md-6 col mb-3 md-lg-0">
									<div className="custom-box-nomargin">
										<span className="info-box-icon bg-blue">
											<i className="ion ion-stats-bars"></i>
										</span>

										<div className="info-box-content">
											<span className="info-box-number">
												{props.dashboardInfo.data.total_tokens}
											</span>
											<span className="info-box-text">Total Tokens</span>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-md-6 col mb-3 md-lg-0">
									<div className="custom-box-nomargin">
										<span className="info-box-icon bg-success">
											<i className="mdi mdi-check-circle-outline"></i>
										</span>

										<div className="info-box-content">
											<span className="info-box-number">
												{props.dashboardInfo.data.deployed_tokens}
											</span>
											<span className="info-box-text">Deployed Tokens</span>
										</div>
									</div>
								</div>
								<div className="clearfix visible-sm-block"></div>

								<div className="col-xl-3 col-md-6 col mb-3 md-lg-0">
									<div className="custom-box-nomargin">
										<span className="info-box-icon bg-purple">
											<i className="mdi mdi-information-outline"></i>
										</span>

										<div className="info-box-content">
											<span className="info-box-number">
												{props.dashboardInfo.data.pending_tokens}
											</span>
											<span className="info-box-text">Pending Tokens</span>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-md-6 col mb-3 md-lg-0">
									<div className="custom-box-nomargin h-100">
										<span className="info-box-icon bg-red">
											<i className="mdi mdi-coin"></i>
										</span>

										<div className="info-box-content">
											<span className="info-box-number">
												{Object.keys(props.dashboardInfo.data.recent_token).length == 0  ? (
													"-"
												) : (
													props.dashboardInfo.data.recent_token.name
												)}
											</span>
											<span className="info-box-text">Recent Token</span>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
					<div className="col-12 p-0">
						{props.tokenList.loading ? (
							"Loading..."
						) : (
							<>
								<div className="table-wrapper" id="dashboard-table">
									<div className="table-heading">
										<h3 className="text-bold m-0 whitecolor">
											Token's List
										</h3>
										<Link to="/tokens/tokens-list" className="mb-0 text-muted">View all</Link>
									</div>
									<TableContainer component={Paper}>
										<Table
											stickyHeader
											aria-label="sticky table"
											className={tableclasses.table}
										>
											<TableHead>
												<TableRow>
													<TableCell></TableCell>
													{/* <TableCell>
                        <span className="text-bold">Logo</span>
                      </TableCell> */}
													<TableCell>
														<span className="text-bold">Token</span>
													</TableCell>
													<TableCell>
														<span className="text-bold">Token Symbol</span>
													</TableCell>
													<TableCell>
														<span className="text-bold">Token Supply</span>
													</TableCell>
													<TableCell>
														<span className="text-bold">Network Type</span>
													</TableCell>
													<TableCell>
														<span className="text-bold">Contract Address</span>
													</TableCell>
													{/* <TableCell>
														<span className="text-bold">Transfer Type</span>
													</TableCell> */}
													<TableCell>
														<span className="text-bold">Status</span>
													</TableCell>
													{/* <TableCell>
														<span className="text-bold">Created</span>
													</TableCell> */}
													<TableCell>
														<span className="text-bold">Action</span>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{props.tokenList.data.crypto_tokens.map((data, index) => (
													<>
														{index < 4 && (
															<TableRow key={index}>
																<TableCell>{index + 1}</TableCell>
																{/* <TableCell>
                            <Link to={`/tokens/single-token/${data.crypto_token_unique_id}`}>
                              <img src={data.picture} className="token-logo"/>
                            </Link>
                          </TableCell> */}
																<TableCell>
																	<Link
																		to={`/tokens/single-token/${data.crypto_token_unique_id}`}
																	>
																		<p className="text-no-overflow mb-0">
																			{" "}
																			{data.name}
																		</p>
																	</Link>
																</TableCell>
																<TableCell>
																	<p className="mb-0"> {data.token_symbol}</p>
																</TableCell>
																<TableCell>
																	<p className="mb-0"> {data.max_token_supply}</p>
																</TableCell>
																<TableCell>
																	<p className="mb-0"> {data.network_type}</p>
																</TableCell>
																<TableCell>
																	<BootstrapTooltip title={data.contract_address}>
																		<span className="text-primary text-no-overflow">
																			{" "}
																			{data.contract_address}
																		</span>
																	</BootstrapTooltip>
																</TableCell>
																
																<TableCell>
																	<BootstrapTooltip title={data.status_formatted}>
																		<span className="text-primary text-no-overflow">
																			{data.status_formatted}
																		</span>
																	</BootstrapTooltip>
																</TableCell>
																{/* <TableCell>
																	<BootstrapTooltip title={data.created_at}>
																		<span className="text-primary text-no-overflow">
																			{" "}
																			{data.created_at}
																		</span>
																	</BootstrapTooltip>
																</TableCell> */}
																<TableCell>
																	<div className="tokens-list-action">
																		{/* <span>
																					<i className="far fa-edit"></i>
																			</span>
																			<span>
																					<i className="far fa-trash-alt text-danger"></i>
																			</span> */}
																		{data.payment_status == 0 ?
																			<BootstrapTooltip title="Make Payment">
																				<Link to={`/tokens/create-token/confirmation/${data.crypto_token_unique_id}`}>
																					<span>
																						<i class="fas fa-money-bill-alt"></i>
																					</span>
																				</Link>
																			</BootstrapTooltip>
																			: ""}

																		{!data.contract_address && data.payment_status == 1 ?
																			<>
																				<BootstrapTooltip title="Deploy">
																					<Link to={`/tokens/deploy-token/${data.crypto_token_unique_id}`}>
																						<span>
																							<i class="fas fa-server"></i>
																						</span>
																					</Link>
																				</BootstrapTooltip>
																			</>
																			: ""}
																	</div>
																</TableCell>
															</TableRow>
														)}
													</>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</>
						)}
					</div>

					{/* <div className="row">
            <div className="col-lg-12 col-12">
              <div className="box">
                <div className="box-body">
                  <div className="media align-items-center p-0">
                    <div className="text-center">
                      <a href="#">
                        <i className="cc BTC mr-5" title="BTC"></i>
                      </a>
                    </div>
                    <div>
                      <h3 className="no-margin text-bold">Bitcoin BTC</h3>
                    </div>
                  </div>
                </div>
                <div className="box-footer p-0 no-border">
                  <div className="chart">
                    <Line data={firstLineChart} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
										<div className="box">
												<div className="box-body">
														<div className="media align-items-center p-0">
																<div className="text-center">
																		<a href="#"><i className="cc LTC mr-5" title="LTC"></i></a>
																</div>
																<div>
																		<h3 className="no-margin text-bold">Litecoin LTC</h3>
																</div>
														</div>
												</div>
												<div className="box-footer p-0 no-border">
														<div className="chart">
															<Line data={secondLineChart} />
														</div>
												</div>
										</div>
								</div>
								<div className="col-lg-4 col-12">
										<div className="box">
												<div className="box-body">
														<div className="media align-items-center p-0">
																<div className="text-center">
																		<a href="#"><i className="cc NEO mr-5" title="NEO"></i></a>
																</div>
																<div>
																		<h3 className="no-margin text-bold">Neo NEO</h3>
																</div>
														</div>
												</div>
												<div className="box-footer p-0 no-border">
														<div className="chart">
																<Line data={thirdLineChart} />
														</div>
												</div>
										</div>
								</div>
          </div> */}
					{/* <div className="row">
								<div className="col-12">
										<div className="box box-inverse box-dark">
												<div className="box-body tickers-block">
														<ul id="webticker-1">
																<li><i className="cc BTC"></i> BTC <span className="text-yellow"> $11.039232</span></li>
																<li><i className="cc ETH"></i> ETH <span className="text-yellow"> $1.2792</span></li>
																<li><i className="cc GAME"></i> GAME <span className="text-yellow"> $11.039232</span></li>
																<li><i className="cc LBC"></i> LBC <span className="text-yellow"> $0.588418</span></li>
																<li><i className="cc NEO"></i> NEO <span className="text-yellow"> $161.511</span></li>
																<li><i className="cc STEEM"></i> STE <span className="text-yellow"> $0.551955</span></li>
																<li><i className="cc LTC"></i> LIT <span className="text-yellow"> $177.80</span></li>
																<li><i className="cc NOTE"></i> NOTE <span className="text-yellow"> $13.399</span></li>
																<li><i className="cc MINT"></i> MINT <span className="text-yellow"> $0.880694</span></li>
																<li><i className="cc IOTA"></i> IOT <span className="text-yellow"> $2.555</span></li>
																<li><i className="cc DASH"></i> DAS <span className="text-yellow"> $769.22</span></li>
														</ul>
												</div>
										</div>
								</div>
								<div className="col-12">
										<div className="box">
												<div className="box-header with-border">
														<h3 className="box-title">Bitcoin Exchange ETH/BTC</h3>
														<div className="box-tools pull-right">
																<button type="button" className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="" data-original-title="Collapse">
																<i className="fa fa-minus"></i></button>
																<button type="button" className="btn btn-box-tool" data-widget="remove" data-toggle="tooltip" title="" data-original-title="Remove">
																<i className="fa fa-times"></i></button>
														</div>
												</div>
												<div className="box-body">
														<div className="chart">
																<LiveChart/>
														</div>
												</div>
										</div>
								</div>
								<div className="col-lg-8 col-12">
										<div className="box">
												<div className="box-header with-border">
														<h3 className="box-title">Monthly Traffic</h3>
														<div className="box-tools pull-right">
																<button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i></button>
																<button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
														</div>
												</div>
												<div className="box-body">
														<div className="chart">
																<div id="chartdiv4" className="height-500"></div>
														</div>
												</div>
										</div>
								</div>
								<div className="col-lg-4 col-12">
										<div className="box">
												<div className="box-header with-border">
														<h3 className="box-title">Traffic Types</h3>
														<div className="box-tools pull-right">
																<button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i></button>
																<button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
														</div>
												</div>
												<div className="box-body">
														<div className="chart">
																<div id="e_chart_5" className="height-500"></div>
														</div>
												</div>
										</div>
								</div>
						</div> */}
					{/* <div className="row">
								<div className="col-lg-4 col-12">
										<div className="box">
												<div className="box-header with-border">
														<h3 className="box-title">Data Tables Markets</h3>
												</div>
												<div className="box-body">
														<div className="table-responsive">
																<table id="example1" className="table table-striped table-bordered no-margin">
																		<thead>
																				<tr className="bg-pale-dark">
																						<th>Coin</th>
																						<th>Price</th>
																						<th>Change %</th>
																				</tr>
																		</thead>
																		<tbody>
																				<tr>
																						<td>
																								<p className="font-size-18 no-margin">BTC</p>
																						</td>
																						<td>
																								<p className="no-margin"><span>$</span> 11,723.40</p>
																						</td>
																						<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -15.20%</span></td>
																				</tr>
																				<tr>
																						<td>
																								<p className="font-size-18 no-margin">ETH</p>
																						</td>
																						<td>
																								<p className="no-margin"><span>$</span> 1,070.39</p>
																						</td>
																						<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -11.74%</span></td>
																				</tr>
																				<tr>
																						<td>
																								<p className="font-size-18 no-margin">XRP</p>
																						</td>
																						<td>
																								<p className="no-margin"><span>$</span> 1.64</p>
																						</td>
																						<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -16.44%</span></td>
																				</tr>
																				<tr>
																						<td>
																								<p className="font-size-18 no-margin">ADA</p>
																						</td>
																						<td>
																								<p className="no-margin"><span>$</span> 0.68</p>
																						</td>
																						<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -5.91%</span></td>
																				</tr>
																				<tr>
																						<td>
																								<p className="font-size-18 no-margin">LTC</p>
																						</td>
																						<td>
																								<p className="no-margin"><span>$</span> 198.88</p>
																						</td>
																						<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -15.74%</span></td>
																				</tr>
																				<tr>
																						<td>
																								<p className="font-size-18 no-margin">DASH</p>
																						</td>
																						<td>
																								<p className="no-margin"><span>$</span> 865.27</p>
																						</td>
																						<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -16.47%</span></td>
																				</tr>
																				<tr>
																						<td>
																								<p className="font-size-18 no-margin">EOS</p>
																						</td>
																						<td>
																								<p className="no-margin"><span>$</span> 10.57</p>
																						</td>
																						<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -6.43%</span></td>
																				</tr>
																				<tr>
																						<td>
																								<p className="font-size-18 no-margin">XMR</p>
																						</td>
																						<td>
																								<p className="no-margin"><span>$</span> 336.11</p>
																						</td>
																						<td className="no-wrap"><span className="badge badge-danger"><i className="fa fa-chevron-down"></i> -9.78%</span></td>
																				</tr>
																		</tbody>
																</table>
														</div>
												</div>
										</div>    
								</div>
								<div className="col-lg-8 col-12">
										<div className="box">
												<div className="box-header with-border">
														<h3 className="box-title">Latest Transactions</h3>
														<div className="box-tools pull-right">
																<button type="button" className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip"
																		title="Collapse">
																<i className="fa fa-minus"></i></button>
																<button type="button" className="btn btn-box-tool" data-widget="remove" data-toggle="tooltip" title="Remove">
																<i className="fa fa-times"></i></button>
														</div>
												</div>
												<div className="box-body">
														<div className="table-responsive">
																<table className="table table-bordered mb-10">
																		<thead>
																				<tr className="bg-pale-dark">
																						<th>Transaction Hash</th>
																						<th>BTC</th>
																						<th>Time</th>
																						<th>Miner Preference</th>
																						<th>Status</th>
																				</tr>
																		</thead>
																		<tbody>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								9d2c7b06bfa0
																								</a>
																								...
																						</td>
																						<td>1.2126281 BTC</td>
																						<td>
																								<time className="timeago" dateTime="2018-02-01T13:38:01Z" title="2018-02-01 13:38 GMT">2 minutes ago</time>
																						</td>
																						<td>medium</td>
																						<td><span className="label label-success">Confirmed</span></td>
																				</tr>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								5de67415bfc6
																								</a>
																								...
																						</td>
																						<td>0.20522881 BTC</td>
																						<td>
																								<time className="timeago" dateTime="2018-02-01T13:38:01Z" title="2018-02-01 13:38 GMT">2 minutes ago</time>
																						</td>
																						<td>high</td>
																						<td><span className="label label-warning">Unconfirmed</span></td>
																				</tr>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								733de15b3cec
																								</a>
																								...
																						</td>
																						<td>2.02622033 BTC</td>
																						<td>
																								<time className="timeago" dateTime="2018-02-01T13:38:01Z" title="2018-02-01 13:38 GMT">2 minutes ago</time>
																						</td>
																						<td>high</td>
																						<td><span className="label label-success">Confirmed</span></td>
																				</tr>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								6793bcfa4f7f
																								</a>
																								...
																						</td>
																						<td>2.43220578 BTC</td>
																						<td>
																								<time className="timeago" dateTime="2018-02-01T13:38:00Z" title="2018-02-01 13:38 GMT">2 minutes ago</time>
																						</td>
																						<td>high</td>
																						<td><span className="label label-danger">Canceled</span></td>
																				</tr>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								2c66087936b5
																								</a>
																								...
																						</td>
																						<td>14.01099978 BTC</td>
																						<td>
																								<time className="timeago"  dateTime="2018-02-01T13:38:00Z" title="2018-02-01 13:38 GMT">2 minutes ago</time>
																						</td>
																						<td>high</td>
																						<td><span className="label label-danger">Canceled</span></td>
																				</tr>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								51935e53c294
																								</a>
																								...
																						</td>
																						<td>0.3024534 BTC</td>
																						<td>
																								<time className="timeago" dateTime="2018-02-01T13:38:00Z" title="2018-02-01 13:38 GMT">2 minutes ago</time>
																						</td>
																						<td>high</td>
																						<td><span className="label label-warning">Unconfirmed</span></td>
																				</tr>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								a3976b73cf5e
																								</a>
																								...
																						</td>
																						<td>0.20518486 BTC</td>
																						<td>
																								<time className="timeago" dateTime="2018-02-01T13:37:59Z" title="2018-02-01 13:37 GMT">2 minutes ago</time>
																						</td>
																						<td>medium</td>
																						<td><span className="label label-success">Confirmed</span></td>
																				</tr>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								g011cb48c078
																								</a>
																								...
																						</td>
																						<td>2.08039395 BTC</td>
																						<td>
																								<time className="timeago" dateTime="2018-02-01T13:37:59Z" title="2018-02-01 13:37 GMT">2 minutes ago</time>
																						</td>
																						<td>high</td>
																						<td><span className="label label-success">Confirmed</span></td>
																				</tr>
																				<tr>
																						<td>
																								<a href="#" className="text-yellow hover-warning">
																								c6b59368635c
																								</a>
																								...
																						</td>
																						<td>42.99698306 BTC</td>
																						<td>
																								<time className="timeago" dateTime="2018-02-01T13:37:58Z" title="2018-02-01 13:37 GMT">2 minutes ago</time>
																						</td>
																						<td>high</td>
																						<td><span className="label label-success">Confirmed</span></td>
																				</tr>
																		</tbody>
																</table>
														</div>
												</div>
										</div>
								</div>
								<div className="col-lg-5 col-12">
										<div className="box">
												<div className="box-header with-border">
														<h3 className="box-title">Daly Analysis</h3>
														<div className="box-tools pull-right">
																<button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i></button>
																<button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
														</div>
												</div>
												<div className="box-body">
														<div className="chart">
																<div id="e_chart_2" className="height-500" ></div>
														</div>
												</div>
										</div>
								</div>
								<div className="col-lg-7 col-12">
										<div className="box">
												<div className="box-header with-border">
														<h3 className="box-title">Market Depth</h3>
														<div className="box-tools pull-right">
																<button type="button" className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="" data-original-title="Collapse">
																<i className="fa fa-minus"></i></button>
																<button type="button" className="btn btn-box-tool" data-widget="remove" data-toggle="tooltip" title="" data-original-title="Remove">
																<i className="fa fa-times"></i></button>
														</div>
												</div>
												<div className="box-body">
														<div className="chart">
																<div id="e_chart_3 " className="height-500"></div>
														</div>
												</div>
										</div>
								</div>
						</div> */}
				</section>
			</div>
		</>
	);
};

const mapStateToPros = (state) => ({
	dashboardInfo: state.dashboard.dashboardInfo,
	tokenList: state.token.tokenList,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DashBoard);
