import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  fetchSingleTokenStart,
  saveTokenContractAddrStart,
} from "../store/actions/TokenAction";
import { Redirect, useParams } from "react-router";
import Web3 from "web3";
import PolygonToken from "../../abis/PolygonToken.json";
import BinanceToken from "../../abis/BinanceToken.json";
import EtherToken from "../../abis/EtherToken.json";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";

const DeployContract = (props) => {
  const { token_unique } = useParams();

  useEffect(() => {
    props.dispatch(
      fetchSingleTokenStart({ crypto_token_unique_id: token_unique })
    );
    // Just loading blockchain.
    loadWeb3();
    // get amount api call.
    // get the wallet address api call.
  }, []);

  const etherNetID = 1;

  const binanceNetID = 56;

  const polygonNetID = 137; // For test 8001

  const [walletAddress, setWalletAddress] = useState("");

  const [loadinBlockchain, setLoadingBlockchain] = useState(true);

  const [loading, setLoading] = useState(true);

  const [account, setAccount] = useState("");

  const [ethBalance, setEthBalance] = useState("0");

  const [token, setToken] = useState("");

  const [crytoSymbol, setCrytoSymbol] = useState("ETH");

  const [gasEstimation, setGasEstimation] = useState("0");

  const [metamaskNetStatus, setMetamaskNetStatus] = useState("ether");

  const [deployContractStatus, setDeployContractStatus] = useState(false);

  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const [deployContractButtonContent, setDeployContractButtonContent] =
    useState("");

  useEffect(() => {
    if (deployContractButtonContent !== "") {
      window.onbeforeunload = function () {
        console.log("refreshed..!!");
        return true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [deployContractButtonContent]);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setLoadingBlockchain(false);
      loadBlockchainData();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setLoadingBlockchain(false);
      loadBlockchainData();
      return true;
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      return false;
    }
  };

  const findNet = async () => {
    const web3 = window.web3;
    const getNetworkID = await web3.eth.net.getId();
    switch (getNetworkID) {
      case etherNetID:
        setMetamaskNetStatus("ether");
        return "ETH";
        break;
      case binanceNetID:
        setMetamaskNetStatus("bnb");
        return "BNB";
        break;
      case polygonNetID:
        setMetamaskNetStatus("polygon");
        return "MATIC";
        break;
      default:
        return "";
        break;
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    setAccount(accounts[0]);

    const ethBalance = await web3.eth.getBalance(accounts[0]);
    setEthBalance(ethBalance);

    findNet().then((val) => setCrytoSymbol(val));
    setLoading(false);

    estimateGasPrice();
  };

  const estimateGasPrice = () => {
    const web3 = window.web3;
    web3.eth.getGasPrice().then((result) => {
      setGasEstimation(web3.utils.fromWei(result, "ether"));
    });
  };

  const deployContract = async (TokenDetails, values) => {
    setDeployContractButtonContent("Deploying contract...");
    const web3 = window.web3;
    const erc20Token = new web3.eth.Contract(TokenDetails.abi);
    const max_token_supply = values.max_token_supply.toString();
    try {
      const res = await erc20Token
        .deploy({
          data: TokenDetails.bytecode,
          arguments: [
            values.name,
            values.token_symbol,
            max_token_supply,
            values.token_decimals,
          ],
        })
        .send(
          {
            from: account,
            gas: 5000000,
            gasPrice: 25000000000,
          },
          function (error, transactionHash) {
            // API call....
            console.log("Txt", transactionHash);
          }
        )
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("con", confirmationNumber);
        })
        .then(async (newContractInstance) => {
          console.log("New token created.", newContractInstance.address);
          console.log(
            "name",
            await newContractInstance.methods.name.call().toString()
          );
          props.dispatch(
            saveTokenContractAddrStart({
              crypto_token_id:
                props.tokenDetails.data.crypto_token.crypto_token_id,
              contract_address: newContractInstance.options.address,
            })
          );
          const notificationMessage = getSuccessNotificationMessage(
            "Contract deployed. Please check the metamask.."
          );
          props.dispatch(createNotification(notificationMessage));
          console.log(newContractInstance.options.address); // instance with the new contract address
          setDeployContractButtonContent("");
          // Save the token contract address.
        });
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(
        "Failed. Please try again..."
      );
      props.dispatch(createNotification(notificationMessage));
      setDeployContractButtonContent("");
    }
  };

  const checkMetaMaskNetwork = async (value) => {
    const web3 = window.web3;
    const networkId = await web3.eth.net.getId();
    console.log("Network Id", networkId);
    if (value == "ether" && networkId == etherNetID) {
      // network is connected on Ropsten or Ether network.
      setMetamaskNetStatus("ether");
    } else if (value == "bnb" && networkId == binanceNetID) {
      setMetamaskNetStatus("bnb");
    } else if (value == "polygon" && networkId == polygonNetID) {
      setMetamaskNetStatus("polygon");
    } else {
      window.alert(
        "Please change the network into " +
        value +
        ". Once you changed the network in metamask refresh the page."
      );
      setMetamaskNetStatus("");
    }
  };

  const startDeployContractProcess = async () => {
    let tempNetwork = "";
    let token = null;

    switch (props.tokenDetails.data.crypto_token.network_type) {
      case "Ethereum":
        checkMetaMaskNetwork("ether");
        token = EtherToken;
        tempNetwork = "ether";
        break;
      case "Binance":
        checkMetaMaskNetwork("bnb");
        token = BinanceToken;
        tempNetwork = "bnb";
        break;
      case "Polygon":
        checkMetaMaskNetwork("polygon");
        token = PolygonToken;
        tempNetwork = "polygon";
        break;
      default:
        token = null;
        break;
    }
    if (token !== null && tempNetwork === metamaskNetStatus)
      deployContract(token, props.tokenDetails.data.crypto_token);
    else console.log("Issue in generating token..");
  };

  return (
    <>
      <div
        className="content-wrapper min-heigth-100vh transaction-table"
        id="token-confirmation"
      >
        <section className="content-header">
          <h1>Deploy Token Contract</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Tokens</a>
            </li>
            <li className="breadcrumb-item active">Deploy your contract</li>
          </ol>
        </section>
        {
          props.tokenDetails.loading ? (
            "Loading... ?"
          ) : (
            // props.tokenDetails.data.crypto_token.deploy_status == 0 ? (
            //   props.tokenDetails.data.crypto_token.payment_status == 1 ? (
            <>
              <section className="center-container">
                <div className="container-fluid text-center">
                  <div className="custom-box p-3 p-lg-4 custom-shadow m-0 col-lg-9">
                    <div className="row no-gutters">
                      <div className="col-md-6">
                        <div className="tokens-info-wrapper pr-md-4">
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Token Name <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0">
                              {props.tokenDetails.data.crypto_token.name}
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Total Supply <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0">
                              {
                                props.tokenDetails.data.crypto_token
                                  .max_token_supply
                              }
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Token Symbol <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0">
                              {
                                props.tokenDetails.data.crypto_token
                                  .token_symbol
                              }
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Decimal Point <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0">
                              {
                                props.tokenDetails.data.crypto_token
                                  .token_decimals
                              }
                            </h5>
                          </div>
                          <div className="info-wrap">
                            <h5 className="text-bold m-0 whitecolor">
                              Gas Fee <span className="mx-3">:</span>
                            </h5>
                            <h5 className="m-0"> {gasEstimation}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 align-items-center d-flex justify-content-center mt-3 mt-md-0">
                        <div className="admin-amount-wrapper">
                          <div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Gas Fee <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0"> {gasEstimation}</h5>
                            </div>
                            <div className="info-wrap">
                              <h5 className="text-bold m-0 whitecolor">
                                Balance <span className="mx-3">:</span>
                              </h5>
                              <h5 className="m-0">
                                {window.web3.utils.fromWei(ethBalance, "Ether")}{" "}
                                {crytoSymbol}
                              </h5>
                            </div>
                          </div>
                          <RadioGroup
                            aria-label="coin"
                            name="coin"
                            row
                            value={metamaskNetStatus}
                            onChange={(event) =>
                              checkMetaMaskNetwork(event.target.value)
                            }
                          >
                            <FormControlLabel
                              value="ether"
                              control={<Radio color="primary" />}
                              label="Ethereum"
                            />
                            <FormControlLabel
                              value="polygon"
                              control={<Radio color="primary" />}
                              label="Polygon"
                            />
                            <FormControlLabel
                              value="bnb"
                              control={<Radio color="primary" />}
                              label="BNB"
                            />
                          </RadioGroup>

                          <div className="mt-2 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary withTheme"
                              onClick={startDeployContractProcess}
                              disabled={
                                deployContractButtonContent !== ""
                                  ? true
                                  : false
                              }
                            >
                              {deployContractButtonContent !== ""
                                ? deployContractButtonContent
                                : "Deploy Contract"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )
          //   ) : (
          //     <Redirect to="/tokens/tokens-list" />
          //   )
          // ) : (
          //   <Redirect to="/tokens/tokens-list" />
          // )
        }
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  tokenDetails: state.token.tokenDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeployContract);
