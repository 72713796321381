import React from 'react'
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const Sidebar = (props) => {
    return (
        <>
            <aside className="main-sidebar">
                <section className="sidebar">
                    <div className="user-panel">
                        <div className="ulogo p-3">
                            <Link to="/dashboard">
                                <img
                                    src={configuration.get("configData.site_logo")}
                                    alt={configuration.get("configData.site_name")}
                                    className="woox-icon woox-logo"
                                />
                            </Link>
                        </div>
                        {/* <div className="image">
                        {props.profile.loading ? (
                            <img src={window.location.origin + "/assets/images/user2-160x160.jpg"} className="rounded-circle" alt="User Image" />
                        ) : (
                            <img src={props.profile.data.picture} className="rounded-circle" alt="User Image" />
                        )}
                    </div> */}
                        <div className="info">
                            {props.profile.loading ? "loading..." : (
                                <p className=" m-0">{props.profile.data.name}</p>
                            )}
                        </div>
                    </div>
                    <ul className="sidebar-menu" data-widget="tree">
                        <li className="nav-devider"></li>
                        <li className="header nav-small-cap">PERSONAL</li>
                        <li className="">
                            <Link to="/dashboard">
                                <i className="fa fa-dashboard"></i> <span>Dashboard</span>
                            </Link>
                        </li>
                        {/* <li className="treeview">
                        <a data-toggle="collapse" href="#reports">
                        <i className="icon-chart"></i>
                        <span>Reports</span>
                        <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                        </span>
                        </a>
                        <ul className="treeview-menu collapse" id="reports">
                            <li><Link to={"/reports/transactions"}>Transactions</Link></li>
                            <li><Link to={"/reports/top-gainers-losers"}>Top Gainers/Losers</Link></li>
                            <li><Link to={"/reports/market-capitalizations"}>Market Capitalizations</Link></li>
                            <li><Link to={"/reports/crypto-stats"}>Crypto Stats</Link></li>
                        </ul>
                    </li> */}
                        <li className="treeview">
                            <a data-toggle="collapse" href="#tokens">
                                <i className="icon-chart"></i>
                                <span>Tokens</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-right pull-right"></i>
                                </span>
                            </a>
                            <ul className="treeview-menu collapse" id="tokens">
                                <li><Link to={"/tokens/create-token"}>Create Token</Link></li>
                                <li><Link to={"/tokens/tokens-list"}>Tokens List</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to={"/transactions"}>
                                <i className="icon-wallet"></i> <span>Transactions</span>
                            </Link>
                        </li>
                        {/* <li className="treeview">
                        <a data-toggle="collapse" href="#initalOffering">
                        <i className="icon-compass"></i>
                        <span>Initial Coin Offering</span>
                        <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                        </span>
                        </a>
                        <ul className="treeview-menu collapse" id="initalOffering">
                            <li><Link to={"/ico-offering/distribution-countdown"}>Countdown</Link></li>
                            <li><Link to={"/ico-offering/roadmap"}>Roadmap/Timeline</Link></li>
                            <li><Link to={"/ico-offering/details"}>Details</Link></li>
                            <li><Link to={"/ico-offering/listings"}>ICO Listing</Link></li>
                            <li><Link to={"/ico-offering/listings-filter"}>ICO Listing - Filters</Link></li>
                        </ul>
                    </li> */}
                        <li className="">
                            <Link to="/profile">
                                <i className="fa fa-user-o"></i> <span>Profile</span>
                            </Link>
                        </li>
                        <li className="">
                            <Link to="/logout">
                                <i class="fa fa-power-off"></i> <span>Logout</span>
                            </Link>
                        </li>
                    </ul>
                </section>
            </aside>
        </>
    )
}

export default Sidebar
