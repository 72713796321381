import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { saveTokenStart } from "../../store/actions/TokenAction";
import { useLocation, useHistory } from "react-router";
import { connect } from "react-redux";

const CreateTokenProcess = (props) => {

  const { state } = useLocation();

  const [progressBar, setProgressBar] = useState(0);

  const [estimatedTimer, setEstimatedTimer] = useState(0);

  const [countDown, setCountDown] = useState(125);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      startProgressbar(60 * 1.42);
      startEstTimer(60 * 1.42);
    }, 1000);
  }, []);

  const startProgressbar = (seconds) => {
    if (loading) {
      return;
    } else {
      setLoading(true);
      var downloadTimer = setInterval(function () {
        if (progressBar == 100) {
          clearInterval(downloadTimer);
        } else {
          setProgressBar((prev) => prev + 1);
        }
      }, seconds/100 * 1000 );
    }
  };

  const startEstTimer = (duration, display) => {
    if (loading) {
      return;
    } else {
      setLoading(true);
      var timer = duration,
        minutes,
        seconds;
      const interval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display = minutes + ":" + seconds;

        setEstimatedTimer(display);
        setCountDown(minutes + seconds);

        if (--timer < 0) {
          timer = duration;
          clearInterval(interval);
          props.dispatch(
            saveTokenStart({
              ...state.data,
              description: "Some description static",
              initial_token_supply: "10000",
            })
          );
        }
      }, 1000);
    }
  };

  return (
    <>
      <section className="landing-other-pages">
        <div className="container">
          <h3 className="text-center">Your Tokens are getting created.</h3>
          <p className="text-center">
            Please <span className="text-bold required">DONT</span> Refresh this
            page or Hit the <span className="text-bold required">BACK</span>{" "}
            button.
          </p>
          <div className="video-wrapper mb-3">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/SDu_rTV_XA8?autoplay=1"
              frameborder="0"
              allow='autoplay'
            ></iframe>
          </div>
          <ProgressBar classname="token-create" animated now={progressBar} />
          <p className="text-center">
            Estimated Time Remaining : {estimatedTimer}
          </p>
          <div className="col-md-6 col-lg-4 mx-auto mt-4">
            <div className="d-flex flex-column">
              {countDown <= 100 && (
                <p className="text-bold">
                  <i className="far fa-check-circle text-success"></i> Smart
                  Contract created.
                </p>
              )}

              {countDown <= 41 && (
                <p className="text-bold">
                  <i className="far fa-check-circle text-success"></i>{" "}
                  Tokenomics applied.
                </p>
              )}
              {countDown <= 23 && (
                <p className="text-bold">
                  <i className="far fa-check-circle text-success"></i> Token's
                  created
                </p>
              )}

              {countDown <= 11 && (
                <p className="text-bold">
                  <i className="far fa-check-circle text-success"></i>{" "}
                  Verification & Check-up done.
                </p>
              )}
              {countDown <= 4 && (
                <p className="text-bold">
                  <i className="far fa-check-circle text-success"></i> All
                  complete
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveToken: state.token.saveToken,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CreateTokenProcess);
