import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const CreateTokenAdminComission = () => {
  return (
    <>
      <section className="landing-other-pages">
        <div className="container">
          <div className="custom-box-landing p-3 p-lg-4 create-token-width custom-shadow">
            <div className="row no-gutters">
              <div className="col-md-6">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <ul class="nav tab-nav tab-nav-s2 tab-nav-center">
                        <li>
                          <a class="active" data-toggle="tab" href="#tab-3-1">
                            Ethereum
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#tab-3-2" class="">
                            BNB
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#tab-3-3" class="">
                            Polygon
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#tab-3-4" class="">
                            BTC
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div class="tab-pane fade active show" id="tab-3-1">
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <h3 class="title title-md">
                              Etherum Wallet Address
                            </h3>
                            <h6 className="black-text">
                              0x81b7e08f65bdf5648606c89998a9cc8164397647
                            </h6>
                            <div>
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/images/qrcode.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3-2">
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <h3 class="title title-md">BNB Wallet Address</h3>
                            <h6 className="black-text">
                              0x81b7e08f65bdf5648606c89998a9cc8164397647
                            </h6>
                            <div>
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/images/qrcode.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3-3">
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <h3 class="title title-md">
                              Polygon Wallet Address
                            </h3>
                            <h6 className="black-text">
                              0x81b7e08f65bdf5648606c89998a9cc8164397647
                            </h6>
                            <div>
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/images/qrcode.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="tab-3-4">
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <h3 class="title title-md">BTC Wallet Address</h3>
                            <h6 className="black-text">
                              0x81b7e08f65bdf5648606c89998a9cc8164397647
                            </h6>
                            <div>
                              <img
                                src={
                                  window.location.origin +
                                  "/assets/images/qrcode.png"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 align-items-center d-flex justify-content-center my-3 my-md-0">
                <div className="admin-amount-wrapper">
                  <div>
                    <h4>
                      {" "}
                      <span className="black-text text-bold">Pay Amount</span> :
                      100USD
                    </h4>
                    <h5 className="black-text">Gas Fee : 0.05ETH</h5>
                    <h5 className="black-text">
                      Transaction Fee : 0.000015ETH
                    </h5>
                  </div>
                  <p className="text-bold my-4">
                    <span className="invalid-feedback d-inline">Note :</span> We
                    accept ETH,BNB,Polygon,BTC.
                  </p>
                  <ul className="list list-check list-check-s3 list-check-s3-sm w-100 my-4">
                    <li className="text-bold mb-3">
                      <p>
                        Ether : 150 Ether <i class="fab fa-ethereum"></i>
                      </p>
                    </li>
                    <li className="text-bold mb-3">
                      <p>
                        BTC : 0.001 BTC <i class="fab fa-bitcoin"></i>
                      </p>
                    </li>
                  </ul>
                  {/* <ul class="d-flex flex-wrap gutter-30px">
                    <li class="mb-3">
                      <div class="field-wrap">
                        <input
                          class="input-radio"
                          id="rdi-1"
                          name="rdi"
                          type="radio"
                          required=""
                          checked
                        />
                        <label
                          for="rdi-1"
                          className="d-flex align-items-center"
                        >
                          <span className="text-bold ml-1">Ethereum</span>
                        </label>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="field-wrap">
                        <input
                          class="input-radio"
                          id="rdi-2"
                          name="rdi"
                          type="radio"
                          required=""
                        />
                        <label
                          for="rdi-2"
                          className="d-flex align-items-center"
                        >
                          <span className="text-bold ml-1">BNB</span>
                        </label>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="field-wrap">
                        <input
                          class="input-radio"
                          id="rdi-3"
                          name="rdi"
                          type="radio"
                          required=""
                        />
                        <label
                          for="rdi-3"
                          className="d-flex align-items-center"
                        >
                          <span className="text-bold ml-1">Polygon</span>
                        </label>
                      </div>
                    </li>
                    <li class="mb-3">
                      <div class="field-wrap">
                        <input
                          class="input-radio"
                          id="rdi-4"
                          name="rdi"
                          type="radio"
                          required=""
                        />
                        <label
                          for="rdi-4"
                          className="d-flex align-items-center"
                        >
                          <span className="text-bold ml-1">BTC</span>
                        </label>
                      </div>
                    </li>
                  </ul> */}
                  <RadioGroup aria-label="coin" name="coin" row >
                    <FormControlLabel
                      value="ether"
                      control={<Radio color="primary" />}
                      label="Ethereum"
                    />
                    <FormControlLabel
                      value="polygon"
                      control={<Radio color="primary" />}
                      label="Polygon"
                    />
                    <FormControlLabel
                      value="btc"
                      control={<Radio color="primary" />}
                      label="bitcoin"
                    />
                    <FormControlLabel
                      value="bnb"
                      control={<Radio color="primary" />}
                      label="BNB"
                    />
                  </RadioGroup>
                  <div className="my-3 my-lg-4">
                    <button
                      type="submit"
                      className="btn btn-md btn-medium btn-grad no-change p-3 mx-auto d-block"
                    >
                      Click here to pay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateTokenAdminComission;
