import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faInbox,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import configuration from "react-global-configuration";
import { Helmet } from "react-helmet";

const HeaderIndex = (props) => {
  const [searchBar, setSearchBar] = useState(false);

  const toggleSearchbar = (status) => {
    setSearchBar(status);
  };

  return (
    <>
      <header className="main-header">
        <Link to="/" className="logo">
          <b className="logo-mini">
            <span className="light-logo">
              <img
                src={configuration.get("site_icon")}
                srcSet={configuration.get("site_icon")}
                alt={configuration.get("site_name")}
              />
            </span>
            <span className="dark-logo">
              <img
                src={configuration.get("site_icon")}
                srcSet={configuration.get("site_icon")}
                alt={configuration.get("site_name")}
              />
            </span>
          </b>
          <span className="logo-lg">
            <img
              src={
                configuration.get("site_logo")
                  ? configuration.get("site_logo")
                  : window.location.origin + "/logo-white.png"
              }
              srcSet={
                configuration.get("site_logo")
                  ? configuration.get("site_logo")
                  : window.location.origin + "/logo-white.png"
              }
              alt={configuration.get("site_name")}
              className="light-logo"
            />
            <img
              src={
                configuration.get("site_logo")
                  ? configuration.get("site_logo")
                  : window.location.origin + "/logo.png"
              }
              srcSet={
                configuration.get("site_logo")
                  ? configuration.get("site_logo")
                  : window.location.origin + "/logo.png"
              }
              alt={configuration.get("site_name")}
              className="dark-logo"
            />
          </span>
        </Link>
        <nav className="navbar navbar-static-top">
          <a
            href="#"
            className="sidebar-toggle"
            data-toggle="push-menu"
            role="button"
          >
            <span className="sr-only">Toggle navigation</span>
          </a>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  {props.profile.loading ? (
                    <img
                      src={
                        window.location.origin +
                        "/assets/images/user5-128x128.jpg"
                      }
                      className="user-image rounded-circle"
                      alt="User Image"
                    />
                  ) : (
                    <img
                      src={props.profile.data.picture}
                      className="user-image rounded-circle"
                      alt="User Image"
                    />
                  )}
                </a>
                <ul className="dropdown-menu scale-up">
                  <li className="user-header d-flex">
                    {props.profile.loading ? (
                      <img
                        src={
                          window.location.origin +
                          "/assets/images/user5-128x128.jpg"
                        }
                        className="float-left rounded-circle"
                        alt="User Image"
                      />
                    ) : (
                      <img
                        src={props.profile.data.picture}
                        className="float-left rounded-circle"
                        alt="User Image"
                      />
                    )}
                  </li>
                  <li className="user-body">
                    <div className="row no-gutters">
                      <div className="col-12 text-left">
                        <Link to="/profile">
                          <i className="ion ion-person"></i> My Profile
                        </Link>
                      </div>

                      <div role="separator" className="divider col-12"></div>
                      <div role="separator" className="divider col-12"></div>
                      <div className="col-12 text-left">
                        <Link to="/logout">
                          <i className="fa fa-power-off"></i> Logout
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a href="#" data-toggle="control-sidebar">
                  <i className="fa fa-cog fa-spin"></i>
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(HeaderIndex);
