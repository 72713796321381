import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import Base from "./Base";
import store from "./store";
import { createBrowserHistory as createHistory } from "history";
import "react-redux-notify/dist/ReactReduxNotify.css";
import ScrollToTop from "./Helper/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

const history = createHistory();

AOS.init({
    duration: 500,
    easing: 'ease-in-sine',
    delay: 100,
    startEvent: 'load',
});

class App extends Component {


 render() {
  return (
   <Provider store={store}>
    <Router history={history}>
      <ScrollToTop/>
     <Base />
    </Router>
   </Provider>
  );
 }
}

export default App;
