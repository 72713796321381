import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TokensDropdown from "../transactions/TokensDropdown";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { fetchTokenListStart } from "../store/actions/TokenAction";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    top: "8px",
    color: "#fff",
    fontSize: "1em",
    padding: "0.75em",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const useTableStyles = makeStyles({
  table: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#171717",
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      minWidth: "150px",
      maxWidth: "200px",
    },
    "& .MuiTableCell-stickyHeader:first-child": {
      minWidth: "50px",
      maxWidth: "50px",
    },
    "& .MuiTableCell-body": {
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      maxWidth: "200px",
      overflow: "hidden",
    },
  },
  MuiTablePagination: {
    backgroundColor: "red",
  },
});

const usepaginationStyle = makeStyles({
  MuiTablePagination: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiIconButton-root.Mui-disabled": {
      color: "#828282",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
});

const useButtonStyle = makeStyles({
  filterButton: {
    backgroundColor: "transperant",
    color: "#828282",
    borderColor: "#828282",
    zIndex: 1,
    fontWeight: 500,
  },
});

const TokenListing = (props) => {
  const tableclasses = useTableStyles();

  const buttonStyle = useButtonStyle();

  const paginationStyle = usepaginationStyle();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    props.dispatch(fetchTokenListStart());
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="content-wrapper  min-heigth-100vh">
        <section className="content-header">
          <h1>Tokens</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Tokens</a>
            </li>
            <li className="breadcrumb-item active">Tokens List</li>
          </ol>
        </section>
        <div className="container-fluid p-4">
          {props.tokenList.loading ? (
            "Loading..."
          ) : (
            <>
              <div className="table-filter-wrapper">
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, { value: -1, label: "All" }]}
                  component="div"
                  count={props.tokenList.data.crypto_tokens.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  className={paginationStyle.MuiTablePagination}
                  showFirstButton="true"
                  showLastButton="true"
                />
              </div>
              <TableContainer component={Paper}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className={tableclasses.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      {/* <TableCell>
                        <span className="text-bold">Logo</span>
                      </TableCell> */}
                      <TableCell>
                        <span className="text-bold">Token</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-bold">Token Symbol</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-bold">Total Supply</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-bold">Network Type</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-bold">Contract Address</span>
                      </TableCell>
                      {/* <TableCell>
                        <span className="text-bold">Access Type</span>
                      </TableCell>
                      <TableCell>
                        <span className="text-bold">Transfer Type</span>
                      </TableCell> */}
                      <TableCell>
                        <span className="text-bold">Status</span>
                      </TableCell>
                      {/* <TableCell>
                        <span className="text-bold">Created</span>
                      </TableCell> */}
                      <TableCell>
                        <span className="text-bold">Action</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.tokenList.data.crypto_tokens
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          {/* <TableCell>
                            <Link to={`/tokens/single-token/${data.crypto_token_unique_id}`}>
                              <img src={data.picture} className="token-logo"/>
                            </Link>
                          </TableCell> */}
                          <TableCell>
                            <Link
                              to={`/tokens/single-token/${data.crypto_token_unique_id}`}
                            >
                              <p className="text-no-overflow mb-0">
                                {" "}
                                {data.name}
                              </p>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <p className="mb-0"> {data.token_symbol}</p>
                          </TableCell>
                          <TableCell>
                            <p className="mb-0"> {data.max_token_supply}</p>
                          </TableCell>
                          <TableCell>
                            <p className="mb-0"> {data.network_type}</p>
                          </TableCell>
                          <TableCell>
                            <p className="mb-0"> {data.contract_address}</p>
                          </TableCell>
                          {/* <TableCell>
                            <BootstrapTooltip title={data.access_type}>
                              <span className="text-primary text-no-overflow">
                                {" "}
                                {data.access_type}
                              </span>
                            </BootstrapTooltip>
                          </TableCell>
                          <TableCell>
                            <BootstrapTooltip title={data.transfer_type}>
                              <p className="mb-0">{data.transfer_type}</p>
                            </BootstrapTooltip>
                          </TableCell> */}
                          <TableCell>
                            <BootstrapTooltip title={data.status_formatted}>
                              <span className="text-primary text-no-overflow">
                                {data.status_formatted}
                              </span>
                            </BootstrapTooltip>
                          </TableCell>
                          {/* <TableCell>
                            <BootstrapTooltip title={data.created_at}>
                              <span className="text-primary text-no-overflow">
                                {" "}
                                {data.created_at}
                              </span>
                            </BootstrapTooltip>
                          </TableCell> */}
                          <TableCell>
                            <div className="">
                              {data.payment_status == 0 ? (
                                <BootstrapTooltip title="Make Payment">
                                  <Link
                                    to={`/tokens/create-token/confirmation/${data.crypto_token_unique_id}`}
                                    className="btn btn-warning btn-flat m-10"
                                  >
                                    <span>
                                      <i class="fas fa-wallet"></i> Make Payment
                                    </span>
                                  </Link>
                                </BootstrapTooltip>
                              ) : (
                                ""
                              )}

                              <Link
                                className="btn btn-info btn-flat m-10"
                                to={`/tokens/single-token/${data.crypto_token_unique_id}`}
                              >
                                <span>
                                  <i class="fas fa-eye"></i> View
                                </span>
                              </Link>

                              {data.payment_status == 1 &&
                              !data.contract_address ? (
                                <>
                                  <BootstrapTooltip title="Deploy">
                                    <Link
                                      className="btn btn-success btn-flat m-10"
                                      to={`/tokens/deploy-token/${data.crypto_token_unique_id}`}
                                    >
                                      <span>
                                        <i class="fas fa-server"></i> Deploy Now
                                      </span>
                                    </Link>
                                  </BootstrapTooltip>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, { value: -1, label: "All" }]}
                component="div"
                count={props.tokenList.data.crypto_tokens.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className={paginationStyle.MuiTablePagination}
                showFirstButton={true}
                showLastButton={true}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  tokenList: state.token.tokenList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TokenListing);
