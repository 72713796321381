import {
  FETCH_SINGLE_TOKEN_START,
  FETCH_SINGLE_TOKEN_SUCCESS,
  FETCH_SINGLE_TOKEN_FAILURE,
  SAVE_TOKEN_START,
  SAVE_TOKEN_SUCCESS,
  SAVE_TOKEN_FAILURE,
  SAVE_TOKEN_PAYMENT_AUTO_START,
  SAVE_TOKEN_PAYMENT_AUTO_SUCCESS,
  SAVE_TOKEN_PAYMENT_AUTO_FAILURE,
  SAVE_TOKEN_PAYMENT_MANUAL_START,
  SAVE_TOKEN_PAYMENT_MANUAL_SUCCESS,
  SAVE_TOKEN_PAYMENT_MANUAL_FAILURE,
  SAVE_TOKEN_CONTRACT_ADDR_START,
  SAVE_TOKEN_CONTRACT_ADDR_SUCCESS,
  SAVE_TOKEN_CONTRACT_ADDR_FAILURE,
  FETCH_TOKEN_LIST_START,
  FETCH_TOKEN_LIST_SUCCESS,
  FETCH_TOKEN_LIST_FAILURE,
  FETCH_PAYMENT_DETAILS_START,
  FETCH_PAYMENT_DETAILS_SUCCESS,
  FETCH_PAYMENT_DETAILS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  saveToken: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  saveTokenPayAuto: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  saveTokenPayManual: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  saveTokenContract: {
    inputData: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  tokenList: {
    data: {},
    loading: true,
    error: false,
  },
  tokenDetails: {
    data: {},
    loading: true,
    error: false,
    inputData: {}
  },
  paymentInfo: {
    data: {},
    loading: true,
    error: false,
  },
};

const TokenReducer = (state = initialState, action) => {
  switch (action.type) {

    case SAVE_TOKEN_START:
      return {
        ...state,
        saveToken: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case SAVE_TOKEN_SUCCESS:
      return {
        ...state,
        saveToken: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case SAVE_TOKEN_FAILURE:
      return {
        ...state,
        saveToken: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };

    case SAVE_TOKEN_PAYMENT_AUTO_START:
      return {
        ...state,
        saveTokenPayAuto: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case SAVE_TOKEN_PAYMENT_AUTO_SUCCESS:
      return {
        ...state,
        saveTokenPayAuto: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case SAVE_TOKEN_PAYMENT_AUTO_FAILURE:
      return {
        ...state,
        saveTokenPayAuto: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };

    case SAVE_TOKEN_PAYMENT_MANUAL_START:
      return {
        ...state,
        saveTokenPayManual: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case SAVE_TOKEN_PAYMENT_MANUAL_SUCCESS:
      return {
        ...state,
        saveTokenPayManual: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case SAVE_TOKEN_PAYMENT_MANUAL_FAILURE:
      return {
        ...state,
        saveTokenPayManual: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };

    case SAVE_TOKEN_CONTRACT_ADDR_START:
      return {
        ...state,
        saveTokenContract: {
          inputData: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Processing...",
          successData: {},
        },
      };
    case SAVE_TOKEN_CONTRACT_ADDR_SUCCESS:
      return {
        ...state,
        saveTokenContract: {
          inputData: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: action.data,
        },
      };
    case SAVE_TOKEN_CONTRACT_ADDR_FAILURE:
      return {
        ...state,
        saveTokenContract: {
          inputData: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          successData: {},
        },
      };

    case FETCH_SINGLE_TOKEN_START:
      return {
        ...state,
        tokenDetails: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_TOKEN_SUCCESS:
      return {
        ...state,
        tokenDetails: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {}
        },
      };
    case FETCH_SINGLE_TOKEN_FAILURE:
      return {
        ...state,
        tokenDetails: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {}
        },
      };

    case FETCH_TOKEN_LIST_START:
      return {
        ...state,
        tokenList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_TOKEN_LIST_SUCCESS:
      return {
        ...state,
        tokenList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_TOKEN_LIST_FAILURE:
      return {
        ...state,
        tokenList: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case FETCH_PAYMENT_DETAILS_START:
      return {
        ...state,
        paymentInfo: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        paymentInfo: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        paymentInfo: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default TokenReducer;
