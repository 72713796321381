import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import AdditionalInfo from "./AdditionalInfo";
import TokensDropdown from "./TokensDropdown";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { fetchTokenListStart } from "../store/actions/TokenAction";
import moment from "moment";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    top: "8px",
    color: "#fff",
    fontSize: "1em",
    padding: "0.75em",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const useTableStyles = makeStyles({
  table: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#171717",
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      minWidth: "180px",
      maxWidth: "200px",
    },
    "& .MuiTableCell-body": {
      color: "#828282",
      borderBottom: "1.5px solid #828282",
      maxWidth: "200px",
      overflow: "hidden",
    },
  },
  MuiTablePagination: {
    backgroundColor: "red",
  },
});

const usepaginationStyle = makeStyles({
  MuiTablePagination: {
    backgroundColor: "#171717",
    color: "#828282",
    "& .MuiIconButton-root.Mui-disabled": {
      color: "#828282",
      cursor: "not-allowed",
      pointerEvents: "auto",
    },
  },
});

const useButtonStyle = makeStyles({
  filterButton: {
    backgroundColor: "transperant",
    color: "#828282",
    borderColor: "#828282",
    zIndex: 1,
    fontWeight: 500,
  },
});

const Transactions = (props) => {
  const tableclasses = useTableStyles();

  const buttonStyle = useButtonStyle();

  const paginationStyle = usepaginationStyle();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [tokenDropdownFlag, setTokenDropdownFlag] = useState(false);

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(undefined);

  const [filterToken, setFilterToken] = useState(null);

  const [transactionDetails, setTransactionDetails] = useState("");

  const etherScanAPIKey = configuration.get("configData.ether_api_key");

  const [transactionLoading, setTransactionLoading] = useState(false);

  useEffect(() => {
    props.dispatch(fetchTokenListStart());
  }, []);

  useEffect(() => {
    if (!props.tokenList.loading) {
      const deployedTokens = props.tokenList.data.crypto_tokens.filter((data) =>
        data.deploy_status == 1 ? data.deploy_status : null
      );
      if (deployedTokens.length > 0) {
        setTransactionLoading(true);
        getTransactionDetails(deployedTokens[0].contract_address);
        setFilterToken(deployedTokens[0].name);
      }
    }
  }, [props.tokenList.data]);

  const getTransactionDetails = (contract_address) => {
    fetch(
      "https://api-testnet.polygonscan.com/api?module=account&action=tokentx&contractaddress=" +
      contract_address +
      "&page=1&offset=100&sort=asc&apikey=" +
      etherScanAPIKey
    )
      .then((response) => response.json())
      .then((data) => setTransactionDetails(data))
      .then(() => setTransactionLoading(false));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTokenDropdown = (event) => {
    setTokenDropdownFlag(true);
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setTokenDropdownFlag(false);
  };

  const handleFilterTokensChange = (name, address) => {
    setTransactionLoading(true);
    setFilterToken(name);
    // props.tokenList.data.crypto_tokens.map((tokens) => {
    //   if (tokens.crypto_token_unique_id == value) {
    //     getTransactionDetails(tokens.contract_address);
    //   }
    // });
    getTransactionDetails(address);
    setTokenDropdownFlag(false);
  };

  return (
    <>
      <div className="content-wrapper min-heigth-100vh transaction-table">
        <section className="content-header">
          <h1>Transactions</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">
                <i className="fa fa-dashboard"></i> Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Transactions</a>
            </li>
          </ol>
        </section>
        <div className="container-fluid p-4">
          {props.tokenList.loading ? (
            "loading"
          ) : (
            <>
              <div className="table-filter-wrapper">
                {props.tokenList.data.crypto_tokens &&
                  props.tokenList.data.crypto_tokens.length > 0 ? (
                  <div className="table-token-dropwdown">
                    <Button
                      className={buttonStyle.filterButton}
                      onClick={(event) => handleTokenDropdown(event)}
                      variant="outlined"
                    >
                      <span>
                        <i class="fas fa-filter"></i> Tokens{" "}
                        {filterToken != null && <span>: {filterToken}</span>}
                      </span>
                      {/* {toggleArrow()} */}
                    </Button>
                    <TokensDropdown
                      open={tokenDropdownFlag}
                      anchorEl={dropdownAnchorEl}
                      handleClickAway={handleClickAway}
                      placement={"bottom-start"}
                      handleFilterTokensChange={handleFilterTokensChange}
                      filterToken={filterToken}
                      // tokensList={Array.from(new Set(transactionData.map(tokenlist => tokenlist.token)))}
                      tokensList={props.tokenList.data.crypto_tokens}
                    />
                  </div>
                ) : (
                  ""
                )}
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, { value: -1, label: "All" }]}
                  component="div"
                  count={transactionDetails && transactionDetails.result.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  className={paginationStyle.MuiTablePagination}
                  showFirstButton="true"
                  showLastButton="true"
                />
              </div>

              {transactionLoading ? (
                <div className="table-nodata">
                  <h5 className="text">Loading</h5>
                </div>
              ) : transactionDetails && transactionDetails.result.length > 0 && transactionDetails.status != "0" ? (
                < TableContainer component={Paper}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className={tableclasses.table}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className="transaction-eye-width"></TableCell>
                        <TableCell>
                          <span className="text-bold">Txn Hash</span>
                        </TableCell>
                        <TableCell>
                          <span className="text-bold">Token</span>
                        </TableCell>
                        <TableCell>
                          <BootstrapTooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,">
                            <span className="text-bold">
                              Token Symbol
                              <i class="far fa-question-circle ml-2 "></i>
                            </span>
                          </BootstrapTooltip>
                        </TableCell>
                        <TableCell>
                          <span className="text-bold">Block</span>
                        </TableCell>
                        <TableCell>
                          <span className="text-bold">Age</span>
                        </TableCell>
                        <TableCell>
                          <span className="text-bold">From</span>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <span className="text-bold">To</span>
                        </TableCell>
                        <TableCell>
                          <span className="text-bold">Value</span>
                        </TableCell>
                        <TableCell>
                          <BootstrapTooltip title="(Gas price * Gas used by Txns) in ether">
                            <span className="ml-2 text-bold">
                              Txn Fee{" "}
                              <i class="far fa-question-circle ml-2 "></i>
                            </span>
                          </BootstrapTooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transactionDetails.result
                        .filter((data) =>
                          filterToken != null
                            ? data.tokenName == filterToken
                            : data
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <div>
                                <AdditionalInfo transactionData={data} />
                              </div>
                            </TableCell>
                            <TableCell>
                              <p className="text-no-overflow mb-0">
                                {" "}
                                {data.hash}
                              </p>
                            </TableCell>
                            <TableCell>
                              <p className="text-no-overflow mb-0">
                                {" "}
                                {data.tokenName}
                              </p>
                            </TableCell>
                            <TableCell>
                              <p className="mb-0"> {data.tokenSymbol}</p>
                            </TableCell>
                            <TableCell>
                              <p className="text-no-overflow mb-0 ">
                                {" "}
                                {data.blockNumber}
                              </p>
                            </TableCell>
                            <TableCell>
                              <BootstrapTooltip
                                title={moment(Number(data.timeStamp)).format(
                                  "DD-MM-YYYY h:mm:ss"
                                )}
                              >
                                <p className="text-no-overflow mb-0">
                                  {moment(Number(data.timeStamp)).format(
                                    "DD-MM-YYYY h:mm:ss"
                                  )}
                                </p>
                              </BootstrapTooltip>
                            </TableCell>
                            <TableCell>
                              <BootstrapTooltip title={data.from}>
                                <p className="mb-0 text-primary text-no-overflow">
                                  {" "}
                                  {data.from}
                                </p>
                              </BootstrapTooltip>
                            </TableCell>
                            <TableCell className="text-center">
                              <span className="right-arrow-trans">
                                <i class="fas fa-arrow-right text-success"></i>
                              </span>
                            </TableCell>
                            <TableCell>
                              <BootstrapTooltip title={data.to}>
                                <p className="mb-0 text-primary text-no-overflow">
                                  {" "}
                                  {data.to}
                                </p>
                              </BootstrapTooltip>
                            </TableCell>
                            <TableCell>
                              <p className="mb-0 text-primary text-no-overflow">
                                {" "}
                                {data.value}
                              </p>
                            </TableCell>
                            <TableCell>
                              <p className="mb-0 text-primary text-no-overflow">
                                {" "}
                                {data.gas}
                              </p>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="table-nodata">
                  <h5 className="text">No Transactions Found</h5>
                </div>
              )}

              <TablePagination
                rowsPerPageOptions={[10, 15, 20, { value: -1, label: "All" }]}
                component="div"
                count={transactionDetails && transactionDetails.result.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className={paginationStyle.MuiTablePagination}
                showFirstButton={true}
                showLastButton={true}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  tokenList: state.token.tokenList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Transactions);
