import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import NotFoundIndex from "../Handlers/ErrorPages/NotFoundIndex";
import NoDataFound from "../NoDataFound/NoDataFound";
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../translation/en.json";
import pt from "../translation/pt.json";
import DashBoard from "../Dashboard/DashBoard";
import MainLayout from "../Layouts/MainLayout";
import TransactionIdex from "../reports/ReportTransactionIdex";
import ReportTransactionIdex from "../reports/ReportTransactionIdex";
import ReportGainerLoser from "../reports/ReportGainerLoser";
import MarketCapitalization from "../reports/MarketCapitalization";
import CryptoStats from "../reports/CryptoStats";
import DisctributionCountdown from "../../coinoffering/DisctributionCountdown";
import IcoRoadMap from "../../coinoffering/IcoRoadMap";
import IcoDetails from "../../coinoffering/IcoDetails";
import IcoListing from "../../coinoffering/IcoListing";
import IcoFilter from "../../coinoffering/IcoFilter";
import CurrencyExchange from "../currencyexchange/CurrencyExchange";
import Transactions from "../transactions/Transactions";
import TransactionSearch from "../transactions/TransactionSearch";
import SingleTransaction from "../transactions/SingleTransaction";
import TranscationCounter from "../transactions/TranscationCounter";
import ProfileIndex from "../profile/ProfileIndex";
import EditProfile from "../profile/EditProfile";
import ChangePassword from "../profile/ChangePassword";
import DeleteAccount from "../profile/DeleteAccount";
import TokenListing from "../token/TokenListing";
import SingleToken from "../token/SingleToken";
import CreateToken from "../token/CreateToken";
import Logout from "../auth/Logout";
import LandingPage from "../landing/LandingPage";
import EmptyLayout from "../Layouts/EmptyLayout";
import StaticPages from "../staticpages/StaticPages";
import LandingCreateToken from "../landing/createtoken/LandingCreateToken";
import CreateTokenAdminComission from "../landing/createtoken/CreateTokenAdminComission";
import CreateTokenConfirmation from "../token/CreateTokenConfirmation";
import DeployContract from "../token/DeployContract";
import LandingStaticPages from "../staticpages/LandingStaticPages";
import LogoutLayout from "../Layouts/LogoutLayout";
import LandingCreateTokenPreview from "../landing/createtoken/LandingCreateTokenPreview";
import CreateTokenProcess from "../landing/createtoken/CreateTokenProcess";

const history = createHistory();
const $ = window.$;

setTranslations({ pt, en });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps} {...props}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    let userLoginStatus = localStorage.getItem("userLoginStatus");
    this.state = {
      loading: true,
      configLoading: true,
      authentication:
        userId && accessToken && userLoginStatus == "true" ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");
      accessToken = localStorage.getItem("accessToken");
      userLoginStatus = localStorage.getItem("userLoginStatus");
      this.setState({
        loading: true,
        authentication:
          userId && accessToken && userLoginStatus == "true" ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      console.log("response", response);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }
    return (
      <>
        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            sizes="16x16"
          />
          <link
            rel="apple-touch-icon"
            href={configuration.get("configData.site_icon")}
          />
          <meta
            name="description"
            content={configuration.get("configData.meta_description")}
          ></meta>
          <meta
            name="keywords"
            content={configuration.get("configData.meta_keywords")}
          ></meta>
          <meta
            name="author"
            content={configuration.get("configData.meta_author")}
          ></meta>
        </Helmet>
        <Switch>
          {/* <AppRoute
            path={"/"}
            component={LandingPage}
            exact
            layout={EmptyLayout}
          /> */}
          <AppRoute
            path={"/"}
            component={LandingCreateToken}
            exact
            layout={EmptyLayout}
          />
          <AppRoute
            path={"/create-token"}
            component={LandingCreateToken}
            exact
            layout={EmptyLayout}
          />
          <AppRoute
            path={"/create-token/preview"}
            component={LandingCreateTokenPreview}
            exact
            layout={EmptyLayout}
          />
          <AppRoute
            path={"/create-token/creating-token"}
            component={CreateTokenProcess}
            exact
            layout={EmptyLayout}
          />
          <AppRoute
            path={"/create-token/confirmation/:token_unique"}
            component={CreateTokenAdminComission}
            layout={EmptyLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/dashboard"}
            component={DashBoard}
            exact
            layout={MainLayout}
          />
          <AppRoute
            path={"/ico-offering/distribution-countdown"}
            component={DisctributionCountdown}
            layout={MainLayout}
          />
          <AppRoute
            path={"/ico-offering/roadmap"}
            component={IcoRoadMap}
            layout={MainLayout}
          />
          <AppRoute
            path={"/ico-offering/details"}
            component={IcoDetails}
            layout={MainLayout}
          />
          <AppRoute
            path={"/ico-offering/listings"}
            component={IcoListing}
            layout={MainLayout}
          />
          <AppRoute
            path={"/ico-offering/listings-filter"}
            component={IcoFilter}
            layout={MainLayout}
          />
          <AppRoute
            path={"/currency-exchange"}
            component={CurrencyExchange}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/transactions"}
            component={Transactions}
            layout={MainLayout}
          />
          <AppRoute
            path={"/transcations-search"}
            component={TransactionSearch}
            layout={MainLayout}
          />
          <AppRoute
            path={"/transcations-details"}
            component={SingleTransaction}
            layout={MainLayout}
          />
          <AppRoute
            path={"/transcations-counter"}
            component={TranscationCounter}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            exact
            component={ProfileIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile/edit-profile"}
            component={EditProfile}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile/change-password"}
            component={ChangePassword}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile/delete-account"}
            component={DeleteAccount}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/tokens/tokens-list"}
            component={TokenListing}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/tokens/single-token/:token_unique"}
            component={SingleToken}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/tokens/create-token"}
            exact
            component={CreateToken}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/tokens/create-token/confirmation/:token_unique"}
            component={CreateTokenConfirmation}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/tokens/deploy-token/:token_unique"}
            component={DeployContract}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={LogoutLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/pages/:id"}
            component={StaticPages}
            layout={MainLayout}
          />
          <AppRoute
            path={"/home-pages/:id"}
            component={LandingStaticPages}
            layout={EmptyLayout}
          />

          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;
