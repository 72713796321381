import React from 'react'

const TokenDecimalModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="tokenDecimal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="tokenDecimal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content p-3">
            <div className="modal-header">
              <button
                type="button"
                className="close white-color p-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>The decimals denote the minimum -or- maximum units you can split each token into ( when sending or receiving )</p>
              <p>When picking a suitable value for decimals these rules should be followed:</p>
              <p>* Does the token contract represent an indivisible entity? Then set decimals to 0</p>
              <p>* Does the token contract represent an item with a fixed number of decimal places? Then set decimals to that number</p>
              <p>* If neither of the above apply set decimals to 18</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TokenDecimalModal
