import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { saveSupportContactStart } from "../../store/actions/FaqAction";
import { translate, t } from "react-multi-lang";

const AuthFooter = (props) => {
  const [inputData, setInputData] = useState({});

  const handleContactSubmit = (event) => {
    event.preventDefault();
    props.dispatch(saveSupportContactStart(inputData));
  };
  return (
    <>
      <footer className="nk-footer bg-theme-grad">
        <section
          className="section no-pdy section-contact bg-transparent"
          id="contact"
        >
          <div className="container">
            <div
              className="nk-block block-contact "
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div className="row justify-content-center no-gutters">
                <div className="col-lg-6">
                  <div className="contact-wrap split split-left split-lg-left bg-white">
                    <h5 className="title title-md">Contact Us</h5>
                    <form
                      className="nk-form-submit"
                      onSubmit={handleContactSubmit}
                    // method="post"
                    >
                      <div className="field-item">
                        <input
                          name="name"
                          type="text"
                          className="input-line required"
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              name: event.currentTarget.value,
                            });
                          }}
                        />
                        <label className="field-label field-label-line">
                          Your Name
                        </label>
                      </div>
                      <div className="field-item">
                        <input
                          name="email"
                          type="email"
                          className="input-line required email"
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              email: event.currentTarget.value,
                            });
                          }}
                        />
                        <label className="field-label field-label-line">
                          Your Email
                        </label>
                      </div>
                      <div className="field-item">
                        <input
                          name="mobile"
                          type="number"
                          className="input-line required phone"
                          // onChange={(event) => setMobile(event.target.value)}
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              mobile: event.currentTarget.value,
                            });
                          }}
                        />
                        <label className="field-label field-label-line">
                          Your Mobile
                        </label>
                      </div>
                      <div className="field-item">
                        <textarea
                          name="contact-message"
                          className="input-line input-textarea required"
                          onChange={(event) => {
                            setInputData({
                              ...inputData,
                              message: event.currentTarget.value,
                            });
                          }}
                        ></textarea>
                        <label className="field-label field-label-line">
                          Your Message
                        </label>
                      </div>
                      <input
                        type="text"
                        className="d-none"
                        name="form-anti-honeypot"
                        value=""
                      />
                      <div className="row">
                        <div className="col-sm-4">
                          <button type="submit" className="btn btn-grad" disabled={props.saveSupportContact.buttonDisable}>
                            {props.saveSupportContact.loadingButtonContent !== null ? props.saveSupportContact.loadingButtonContent : "Submit"}
                          </button>
                        </div>
                        <div className="col-sm-8">
                          <div className="form-results"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-wrap split split-right split-lg-right bg-genitian bg-theme tc-light">
                    <div className="d-flex flex-column justify-content-between h-100">
                      <ul className="contact-list">
                        <li>
                          <em className="contact-icon fas fa-building"></em>
                          <div className="contact-text">
                            <h6>{configuration.get("configData.site_name")}</h6>
                            <span>
                              {configuration.get("configData.contact_address")}
                            </span>
                          </div>
                        </li>
                        <li>
                          <em className="contact-icon fas fa-phone"></em>
                          <div className="contact-text">
                            <span>
                              {configuration.get("configData.contact_mobile")}
                            </span>
                          </div>
                        </li>
                        <li>
                          <em className="contact-icon fas fa-envelope"></em>
                          <div className="contact-text low-case">
                            <span>
                              {configuration.get("configData.contact_email")}
                            </span>
                          </div>
                        </li>
                        <li>
                          <em className="contact-icon fas fa-paper-plane"></em>
                          <div className="contact-text">
                            <a
                              href="https://t.me/tokencreator_accelerator"
                              target="_blank"
                            >
                              <span>Join us on Telegram</span>
                            </a>
                          </div>
                        </li>
                      </ul>
                      <div
                        className="contact-social"
                      >
                        <h6>Be a part of our community</h6>
                        <ul className="social-links">
                          {/* <li>
                            <a href="#">
                              <em className="fab fa-twitter"></em>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <em className="fab fa-medium-m"></em>
                            </a>
                          </li>*/}
                          <li>
                            <a href="https://www.facebook.com/Token-Creator-104425381888590">
                              <em className="fab fa-facebook-f"></em>
                            </a>
                          </li>
                          <li>
                            <a href=":https://www.youtube.com/channel/UCXe5oRdezGR5R2NcmhoblHg/featured">
                              <em className="fab fa-youtube"></em>
                            </a>
                          </li>
                          {/* <li>
                            <a href="#">
                              <em className="fab fa-bitcoin"></em>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <em className="fab fa-github"></em>
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-ovm ovm-top ovm-h-60 bg-light"></div>
        </section>

        <section className="section section-footer tc-light bg-transparent">
          <div className="container">
            <div className="nk-block block-footer mgb-m30">
              <div className="row">
                <div className="col-lg-4 col-sm-4">
                  <div
                    className="wgs wgs-menu"
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <h6 className="wgs-title">Questions? Shoot A Mail To:</h6>
                    <a
                      href="mailto:hello@tokencreator.space?Subject=subject here&amp;Body=bodytext"
                      target="_blank"
                    >
                      hello@tokencreator.space
                    </a>
                    <div className="wgs-body">
                      {/* <ul className="wgs-links">
                        {configuration
                          .get("configData.footer_pages2")
                          .map((link, index) => (
                            <li key={index} className="nav-item">
                              <Link
                                to={`/home-pages/${link.static_page_unique_id}`}
                                className="text-capitalize"
                              >
                                {link.title}
                              </Link>
                            </li>
                          ))}
                      </ul> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4">
                  <div
                    className="wgs wgs-menu "
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <h6 className="wgs-title">Lets Talk:</h6>
                    <p>
                      {/* <img
                        style={{ width: "30px", height: "30px" }}
                        src={
                          window.location.origin +
                          "/assets/images/messenger.png"
                        }
                      />{" "} */}
                      FB Messenger : Click{" "}
                      <a
                        href="https://www.messenger.com/t/104425381888590"
                        target="_blank"
                      >
                        Here
                      </a>
                    </p>
                    <div className="wgs-body">
                      {/* <ul className="wgs-links">
                        {configuration
                          .get("configData.footer_pages1")
                          .map((link, index) => (
                            <li key={index} className="nav-item">
                              <Link
                                to={`/home-pages/${link.static_page_unique_id}`}
                                className="text-capitalize"
                              >
                                {link.title}
                              </Link>
                            </li>
                          ))}
                      </ul> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-lg-first">
                  <div
                    className="wgs wgs-text "
                    data-aos="fade-up"
                    data-aos-once="true"
                  >
                    <div className="wgs-body">
                      <h6 className="wgs-title">Our Mission</h6>
                      <p>
                        {configuration.get("tag_name")
                          ? configuration.get("tag_name")
                          : "We foresee a world where anything and everything will be tokenised soon. Asset Tokenization Platform provides a robust, Secure and ready-made platform for you to instantly tokenise your Assets. Also a crowd funding platform to get you attracting investors for the tokenised assets worldwide."}
                      </p>

                      <a href="/" className="wgs-logo">
                        <img
                          style={{ width: "30%" }}
                          src={
                            configuration.get("site_logo")
                              ? configuration.get("site_logo")
                              : window.location.origin + "/logo.png"
                          }
                          srcSet={
                            configuration.get("site_logo")
                              ? configuration.get("site_logo")
                              : window.location.origin + "/logo-white.png"
                          }
                          alt={configuration.get("site_name")}
                        />
                      </a>
                      {configuration.get("configData.copyright_content") && (
                        <p className=" text-capitalize mb-0">
                          {configuration.get("configData.copyright_content")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="nk-ovm shape-b"></div>
      </footer>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveSupportContact: state.faq.saveSupportContact,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(AuthFooter);
